.navbar4-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar4-navbar-interactive {
  width: 100%;
  height: 90px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar4-image1 {
  height: 3rem;
}
.navbar4-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar4-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar4-link1 {
  text-decoration: none;
}
.navbar4-link2 {
  text-decoration: none;
}
.navbar4-link3 {
  text-decoration: none;
}
.navbar4-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}
.navbar4-action2 {
  color: #ffffff;
  width: 120px;
  height: var(--dl-size-size-small);
  display: flex;
  transition: 0.3s;
  border-width: 0px;
  flex-direction: row;
  text-decoration: none;
  background-image: linear-gradient(225deg, rgb(0, 69, 255) 1.00%,rgb(61, 183, 255) 100.00%);
}
.navbar4-action2:hover {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  border-color: var(--dl-color-theme-primary1);
  border-width: 2px;
  background-image: linear-gradient(225deg, rgb(0, 69, 255) 1.00%,rgb(255, 255, 255) 0.00%);
}
.navbar4-burger-menu {
  display: none;
}
.navbar4-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar4-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar4-logo {
  height: 3rem;
}
.navbar4-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar4-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}
.navbar4-text1 {
  display: inline-block;
}
.navbar4-text2 {
  display: inline-block;
}
.navbar4-text3 {
  display: inline-block;
}
.navbar4-text4 {
  display: inline-block;
}
.navbar4-text5 {
  display: inline-block;
}
.navbar4-text6 {
  display: inline-block;
}
@media(max-width: 767px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar4-desktop-menu {
    display: none;
  }
  .navbar4-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar4-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar4-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
