.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text {
  display: inline-block;
}
.home-text01 {
  display: inline-block;
}
.home-text02 {
  display: inline-block;
}
.home-text03 {
  display: inline-block;
}
.home-text04 {
  display: inline-block;
}
.home-text05 {
  display: inline-block;
}
.home-text06 {
  display: inline-block;
}
.home-text07 {
  display: inline-block;
}
.home-text08 {
  display: inline-block;
  text-align: center;
}
.home-text09 {
  font-weight: 700;
}
.home-text11 {
  display: inline-block;
  font-size: 59px;
  text-align: center;
  font-weight: 900;
}
.home-text12 {
  color: var(--dl-color-theme-primary1);
}
.home-text13 {
  color: var(--dl-color-theme-accent2);
}
.home-image {
  width: 1074px;
  height: 775px;
  object-fit: cover;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-medium);
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-style: dashed;
  border-width: 1px;
}
.home-text14 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  text-align: center;
}
.home-text15 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  text-align: center;
}
.home-text16 {
  display: inline-block;
  text-align: center;
}
.home-text17 {
  display: inline-block;
  text-align: center;
}
.home-text18 {
  display: inline-block;
  text-align: center;
}
.home-text19 {
  display: inline-block;
  text-align: center;
}
.home-text20 {
  display: inline-block;
  text-align: center;
}
.home-text21 {
  display: inline-block;
  text-align: center;
}
.home-button {
  width: 175px;
  height: 43px;
  transition: 0.3s;
  border-width: 2px;
  text-decoration: none;
}
.home-button:hover {
  width: 175px;
  border: none;
  background-color: transparent;
  background-image: linear-gradient(225deg, rgb(0, 69, 255) 1.00%,rgb(61, 183, 255) 100.00%);
}
.home-text23 {
  display: inline-block;
}
.home-text25 {
  text-decoration: underline;
}
.home-text26 {
  display: inline-block;
}
.home-text27 {
  display: inline-block;
  align-self: center;
  text-align: center;
}
.home-text28 {
  display: inline-block;
  align-self: center;
  text-align: center;
}
.home-text29 {
  display: inline-block;
}
.home-text30 {
  display: inline-block;
}
.home-text31 {
  display: inline-block;
}
.home-text32 {
  display: inline-block;
}
.home-text33 {
  display: inline-block;
  text-align: center;
}
.home-text34 {
  display: inline-block;
  text-align: center;
}
.home-text35 {
  display: inline-block;
  text-align: center;
}
.home-text36 {
  display: inline-block;
  text-align: center;
}
.home-text37 {
  display: inline-block;
  text-align: center;
}
.home-text38 {
  display: inline-block;
}
.home-text39 {
  display: inline-block;
}
.home-text40 {
  display: inline-block;
}
.home-text41 {
  display: inline-block;
}
.home-text42 {
  display: inline-block;
}
.home-text43 {
  display: inline-block;
}
.home-text44 {
  display: inline-block;
}
.home-text45 {
  display: inline-block;
  text-align: left;
}
.home-text46 {
  display: inline-block;
  text-align: left;
}
.home-text47 {
  display: inline-block;
  text-align: left;
}
.home-text48 {
  display: inline-block;
  text-align: left;
}
.home-text49 {
  display: inline-block;
  text-align: left;
}
.home-text50 {
  display: inline-block;
  text-align: left;
}
@media(max-width: 991px) {
  .home-image {
    width: 885px;
    height: 481px;
  }
}
@media(max-width: 479px) {
  .home-image {
    width: 100%;
  }
  .home-button {
    width: 205px;
  }
  .home-text30 {
    font-size: 31px;
  }
  .home-text31 {
    text-align: left;
  }
}
