.features23-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.features23-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features23-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.features23-container2 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-container3 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent2);
}
.features23-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-container4 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-container5 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent2);
}
.features23-image3 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-container6 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image4 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-container7 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent2);
}
.features23-image5 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text14 {
  display: inline-block;
}
.features23-text15 {
  display: inline-block;
}
.features23-text16 {
  display: inline-block;
}
.features23-text17 {
  display: inline-block;
}
.features23-text18 {
  display: inline-block;
}
.features23-text19 {
  display: inline-block;
  text-align: left;
}
.features23-text20 {
  display: inline-block;
  text-align: left;
}
.features23-text21 {
  display: inline-block;
}
.features23-text22 {
  display: inline-block;
  text-align: center;
}
.features23-text23 {
  display: inline-block;
}
.features23-text24 {
  display: inline-block;
  text-align: left;
}
.features23-text25 {
  display: inline-block;
  text-align: left;
}
.features23-text26 {
  display: inline-block;
  text-align: left;
}
.features23-text27 {
  display: inline-block;
  text-align: left;
}
@media(max-width: 991px) {
  .features23-max-width {
    flex-direction: column;
  }
  .features23-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .features23-container {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .features23-container2 {
    width: 100%;
  }
  .features23-container3 {
    width: 100%;
  }
  .features23-container4 {
    width: 100%;
  }
  .features23-container5 {
    width: 100%;
  }
  .features23-container6 {
    width: 100%;
  }
  .features23-container7 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .features23-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
