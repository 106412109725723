.hero8-header26 {
  gap: var(--dl-space-space-twounits);
}
.hero8-max-width {
  align-self: center;
}
.hero8-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero8-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero8-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hero8-button {
  width: var(--dl-size-size-large);
  height: 43px;
  transition: 0.3s;
  border-width: 0px;
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(225deg, rgb(0, 69, 255) 1.00%,rgb(61, 183, 255) 100.00%);
}
.hero8-button:hover {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 2px;
  background-image: linear-gradient(225deg, rgb(0, 69, 255) 1.00%,rgb(255, 255, 255) 0.00%);
}
.hero8-button1 {
  width: var(--dl-size-size-large);
  height: 43px;
  transition: 0.3s;
  border-width: 2px;
  text-decoration: none;
}
.hero8-button1:hover {
  width: var(--dl-size-size-large);
  border: none;
  background-color: transparent;
  background-image: linear-gradient(225deg, rgb(0, 69, 255) 1.00%,rgb(61, 183, 255) 100.00%);
}
.hero8-text4 {
  display: inline-block;
}
.hero8-text5 {
  display: inline-block;
  font-size: 75px;
  text-align: center;
}
.hero8-text6 {
  display: inline-block;
  text-align: center;
}
.hero8-text7 {
  display: inline-block;
}
.hero8-root-class-name {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
}
@media(max-width: 479px) {
  .hero8-actions {
    width: 100%;
    flex-direction: column;
  }
  .hero8-button {
    width: 50%;
    align-self: center;
    margin-right: 0px;
  }
  .hero8-button1 {
    width: 50%;
    align-self: center;
  }
}
