/* sign-in31.css */
.sign-in31-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.sign-in31-background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.sign-in31-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for readability */
  z-index: 0;
}

.sign-in31-max-width {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  z-index: 100;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}

.sign-in31-form-root {
  gap: var(--dl-space-space-unit);
  width: 600px;
  height: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
}

.sign-in31-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}

.sign-in31-form1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.sign-in31-email {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.sign-in31-textinput {
  width: 100%;
  border-color: var(--dl-color-theme-primary1);
  background-color: transparent;
}

.sign-in31-password {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.sign-in31-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.sign-in31-hide-password {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  justify-content: center;
}

.sign-in31-icon {
  fill: var(--dl-color-theme-neutral-dark);
  width: 24px;
  height: 24px;
}

.sign-in31-textinput1 {
  width: 100%;
  border-color: var(--dl-color-theme-primary1);
  background-color: transparent;
}

.sign-in31-link {
  align-self: flex-end;
  text-decoration: underline none;
}

.sign-in31-container2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}

.sign-in31-button {
  width: 100%;
  align-self: center;
}

.sign-in31-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sign-in31-divider {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.sign-in31-divider1 {
  flex: 1;
  width: var(--dl-size-size-medium);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-primary1);
}

.sign-in31-divider2 {
  flex: 1;
  width: var(--dl-size-size-medium);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-primary1);
}

.sign-in31-button1 {
  width: 100%;
  align-self: center;
}

.sign-in31-sign-up-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
}

.sign-in31-container4 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  bottom: 0px;
  height: 100%;
  display: flex;
  z-index: 10;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(0,0,0,0.4);
}

.sign-in31-text07 {
  display: inline-block;
  text-align: center;
}

.sign-in31-text08 {
  display: inline-block;
  align-self: center;
}

.sign-in31-text09 {
  display: inline-block;
}

.sign-in31-text10 {
  display: inline-block;
  text-align: center;
}

@media(max-width: 991px) {
  .sign-in31-form-root {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .sign-in31-form-root {
    padding: 0px;
  }

  .sign-in31-button {
    width: 100%;
  }

  .sign-in31-button1 {
    width: 100%;
  }
}
